import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Box,
  Paper,
  IconButton,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TelegramIcon from "@mui/icons-material/Telegram";
import TwitterIcon from "@mui/icons-material/X";

// Import the GIF image and main logo image
import nftSneakGif from "../images/nftSneak.gif";
import mainLogo from "../images/mainLogo.png"; // Replace with your actual path

// Define the theme with the pixel font
const theme = createTheme({
  palette: {
    primary: {
      main: "#008080", // Turquoise color
    },
    secondary: {
      main: "#004d4d", // Very dark turquoise
    },
    background: {
      default: "#e0f7f7", // Light turquoise for background
    },
    text: {
      primary: "#002626", // Dark text color
    },
  },
  typography: {
    fontFamily: "'Press Start 2P', sans-serif", // Apply the pixel font here
  },
});

// Function to get current date

function MainPage() {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <AppBar
          position="static"
          sx={{
            boxShadow: "none",
            backgroundColor: "transparent",
            color: "white",
          }}
        >
          <Toolbar>
            <Typography
              variant="h6"
              component="div"
              sx={{
                flexGrow: 1,
                color: "white",
              }}
            >
              <IconButton color="inherit" aria-label="twitter">
                <a
                  href="https://t.me/DrownedInHL"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TelegramIcon sx={{ color: "white" }} />
                </a>
              </IconButton>

              <IconButton color="inherit" aria-label="twitter">
                <a
                  href="https://x.com/DrownedInHL"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TwitterIcon sx={{ color: "white" }} />
                </a>
              </IconButton>
            </Typography>
          </Toolbar>
        </AppBar>
        <Container
          maxWidth="md"
          sx={{
            flex: 1, // Make the container fill the remaining space
            mt: 4,
            backgroundColor: theme.palette.background.default,
            borderRadius: 2,
            p: 3,
          }}
        >
          <Box textAlign="center" mb={4}>
            <Typography variant="h4" gutterBottom>
              Drowned In HyperLiquid
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              $OD
            </Typography>

            {/* Display Main Logo */}
            <img
              src={mainLogo}
              alt="Main Logo"
              width="80%" // Adjust the width to make the image smaller
              style={{ maxWidth: "400px" }} // Set a maximum width for better scaling
            />

            {/* Descriptive Text */}
            <Typography
              variant="body1"
              paragraph
              sx={{
                mt: 4,
                fontWeight: 800,
                maxWidth: "80%", // Adjust the maxWidth for a neat alignment
                margin: "0 auto", // Center the text horizontally
                pt: 2,
                lineHeight: 1.6, // Set a comfortable line height for readability
                fontSize: "1rem", // Adjust the font size as needed
                letterSpacing: "0.5px", // Slight letter spacing for clarity
                color: theme.palette.text.primary, // Keep the text color consistent
              }}
            >
              Pepe stumbled into a shimmering pool of hyper-liquid, its
              iridescent waves pulsating with unearthly energy. Overcome by
              curiosity, he slipped and submerged, absorbing more of the
              substance than any being could endure. The overdose ignited a
              cascade of mutations—his mind expanded with cosmic awareness, his
              body warped into a kaleidoscope of chaotic forms. Forever changed,
              Pepe emerged from the pool as a being of unparalleled power and
              surreal insight, no longer bound by the limitations of his former
              self.
            </Typography>
          </Box>

          {/* Roadmap Section */}
          <Box mb={4}>
            <Typography variant="h5" gutterBottom align="center">
              Roadmap
            </Typography>

            <Box display="flex" justifyContent="space-between">
              <Paper
                elevation={3}
                sx={{
                  p: 3,
                  flex: 1,
                  mx: 1,
                  backgroundColor: "#fdf5e6", // Old paper color
                  border: "1px solid #d4c4a7", // Slight border for the paper
                  boxShadow: "inset 0 0 10px rgba(0, 0, 0, 0.2)",
                }}
              >
                {/* Display current date above the section */}
                <Typography variant="body2" color="textSecondary" mb={2}>
                  2024.12
                </Typography>
                <Typography variant="h5" gutterBottom>
                  Stage 1
                </Typography>
                <Typography variant="body2">
                  <br />
                  Fair launching on Hpump $OD. <br />
                  <br /> Further development of NFTs.
                  <br />
                  <br /> Further website development.
                </Typography>
              </Paper>
              <Paper
                elevation={3}
                sx={{
                  p: 3,
                  flex: 1,
                  mx: 1,
                  backgroundColor: "#fdf5e6", // Old paper color
                  border: "1px solid #d4c4a7", // Slight border for the paper
                  boxShadow: "inset 0 0 10px rgba(0, 0, 0, 0.2)",
                }}
              >
                {/* Display current date above the section */}
                <Typography variant="body2" color="textSecondary" mb={2}>
                  2025.01
                </Typography>
                <Typography variant="h5" gutterBottom>
                  Stage 2
                </Typography>
                <Typography variant="body2">
                  <br />
                  Completion of NFT designs and the release of NFTs
                </Typography>
                <Box mt={2}>
                  {/* Display the GIF */}
                  <img src={nftSneakGif} alt="NFT Sneak Preview" width="100%" />
                </Box>
              </Paper>
              <Paper
                elevation={3}
                sx={{
                  p: 3,
                  flex: 1,
                  mx: 1,
                  backgroundColor: "#fdf5e6", // Old paper color
                  border: "1px solid #d4c4a7", // Slight border for the paper
                  boxShadow: "inset 0 0 10px rgba(0, 0, 0, 0.2)",
                }}
              >
                {/* Display current date above the section */}
                <Typography variant="body2" color="textSecondary" mb={2}>
                  2025.TBD
                </Typography>
                <Typography variant="h5" gutterBottom>
                  Stage 3
                </Typography>
                <Typography variant="body2">
                  <br />
                  Future plans: Web-game to be created, winners of the game will
                  get NFT prize. <br />
                  <br /> Merch release?
                </Typography>
              </Paper>
            </Box>
          </Box>
        </Container>

        {/* Developer Credit Section */}
        <Box
          sx={{
            backgroundColor: "transparent",
            color: "white",
            py: 2,
            textAlign: "center",
            mt: "auto", // Pushes the footer to the bottom
          }}
        >
          <Typography variant="body2">
            Developer:{" "}
            <a
              href="https://twitter.com/0xScary"
              style={{ color: "white", textDecoration: "underline" }}
            >
              @0xScary on X
            </a>
          </Typography>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default MainPage;
